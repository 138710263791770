import React, { useState } from "react";
import "../user-styles.css";
import InputText from "../Reviews/InputText";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { submitInquiry } from "./SubmitHandler";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

const guestsIconPath = apiUrl + "/images/icons/guests.png";
const addIconPath = apiUrl + "/images/icons/add.png";
const subtractIconPath = apiUrl + "/images/icons/subtract.png";
const thumbsupIconPath = apiUrl + "/images/icons/thumbsup.png";

function CreateInquiry(props) {
  const location = useLocation();
  const inquiryProps = location.state?.inquiryProps || {};

  const [totals, setTotals] = useState({
    cleaningFee: inquiryProps.cleaningFee,
    bookingFee: inquiryProps.bookingFee,
    totalTaxAndFees: inquiryProps.totalTaxAndFees,
    total: inquiryProps.total,
    estimatedTotal: inquiryProps.estimatedTotal,
  });

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedStartDate, setSelectedStartDate] = useState(
    inquiryProps.startDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState(inquiryProps.endDate);
  const [numGuests, setNumGuests] = useState(inquiryProps.numGuests);
  const [numDays, setNumDays] = useState(inquiryProps.numDays);

  const [message, setMessage] = useState("");

  const handleMessageChange = (newMessage) => {
    setMessage(newMessage);
  };

  const handleDateChange = (date) => {
    setErrorMessage("");

    if (!date || !date[0] || !date[1]) {
      return;
    }

    setSelectedStartDate(date[0]);
    setSelectedEndDate(date[1]);

    const datesGoodSelected = validateDates(date[0], date[1]);
    if (datesGoodSelected) calculateTotal(date[0], date[1]);
  };

  const handleSubtractGuest = () => {
    if (numGuests === 1) return;
    setNumGuests((prevNumGuests) => prevNumGuests - 1);
  };

  const handleAddGuest = () => {
    if (numGuests === parseInt(inquiryProps.maxGuests, 10)) return;
    setNumGuests((prevNumGuests) => prevNumGuests + 1);
  };

  const validateDates = (startDate, endDate) => {
    // Convert start and end dates to DateTime objects
    const unavailableDates = props.datesUnavailable;

    // Generate an array of dates between start and end dates
    const dateRange = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate)); // Push a copy of currentDate
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    // Check if any of the dates in the date range are in unavailableDates
    if (unavailableDates.length > 0) {
      for (const date of dateRange) {
        if (unavailableDates.includes(date)) {
          document.getElementById("goodDatesDiv").style.display = "none";
          setErrorMessage("Error: Invalid Dates Selected");
          return false; // Return false if any date is unavailable
        }
      }
    }

    const { highestMinNights } = calculateDaysInRates(
      startDate,
      endDate,
      dateRange.length
    );
    const minNights =
      highestMinNights > props.fees.minNights
        ? highestMinNights
        : props.fees.minNights;

    if (!dateRange || dateRange.length - 1 < minNights) {
      document.getElementById("goodDatesDiv").style.display = "none";
      setErrorMessage(
        "Error: This stay does not meet the required minimum nights"
      );
      return false;
    }

    setNumDays(dateRange.length - 1);
    document.getElementById("goodDatesDiv").style.display = "block";
    return true;
  };

  const submit = async () => {
    const updatedData = {
      dateCreated: new Date(),
      email: props.user.email,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      phoneNumber: props.user.phoneNumber,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      numGuests: numGuests,
      numDays: numDays,
      message: message,

      cleaningFee: totals.cleaningFee,
      bookingFee: totals.bookingFee,
      totalTaxAndFees: totals.totalTaxAndFees,
      total: totals.total,
      estimatedTotal: totals.estimatedTotal,
    };

    if (selectedStartDate.length < 4) {
      setErrorMessage("Error: Invalid Start Date");
      return;
    }

    if (selectedEndDate.length < 4) {
      setErrorMessage("Error: Invalid End Date");
      return;
    }

    try {
      let status = await submitInquiry(updatedData);
      if (status === "Success") {
        document.getElementById("bookingDiv").style.display = "none";
        document.getElementById("successDiv").style.display = "block";

        return;
      }
    } catch (error) {}
  };

  const calculateDaysInRates = (startDate, endDate, days) => {
    const matchingDates = props.rates
      .filter(
        (rate) =>
          new Date(rate.rateStart) <= endDate &&
          new Date(rate.rateEnd) >= startDate
      )
      .map((rate) => {
        const rateStart =
          new Date(rate.rateStart) < startDate
            ? startDate
            : new Date(rate.rateStart);
        const rateEnd =
          new Date(rate.rateEnd) > endDate ? endDate : new Date(rate.rateEnd);

        // Calculate the number of days in the rate considering start and end dates
        const diffTime = Math.abs(rateEnd - rateStart);
        const daysInRate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return {
          rate,
          daysInRate,
        };
      });

    // Find the highest minNights among the matching rates
    const highestMinNights = matchingDates.reduce(
      (maxMinNights, entry) =>
        Math.max(maxMinNights, Number(entry.rate.minNights)),
      0
    );

    const daysNotInRates =
      days -
      matchingDates.reduce(
        (total, entry) => total + Number(entry.daysInRate),
        0
      );

    return {
      matchingDates,
      daysNotInRates,
      highestMinNights,
    };
  };

  const calculateTotal = (startDate, endDate) => {
    const days = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );

    const { matchingDates, daysNotInRates } = calculateDaysInRates(
      startDate,
      endDate,
      days
    );

    const defaultDailyRate = Number(props.fees.baseWeekly) / 7; // Replace with your actual default daily rate

    const calculateDailyRateWithDiscounts = (
      matchingDates,
      discountNum,
      totalNotInRates
    ) => {
      return (
        totalNotInRates +
        matchingDates.reduce((total, rateEntry) => {
          // Assuming rate.weekly is the weekly rate for each rate
          const rateWeekly = Number(
            rateEntry.rate.weekly
              ? rateEntry.rate.weekly
              : props.fees.baseWeekly
          );
          const rateDaily = rateWeekly / 7;

          let discount = 0;
          if (discountNum === 1 && rateEntry.rate.discount1) {
            discount = Number(rateEntry.rate.discount1);
          } else if (discountNum === 2 && rateEntry.rate.discount2) {
            discount = Number(rateEntry.rate.discount2);
          } else if (discountNum === 3 && rateEntry.rate.discount3) {
            discount = Number(rateEntry.rate.discount3);
          } else if (discountNum === 4 && rateEntry.rate.discount4) {
            discount = Number(rateEntry.rate.discount4);
          }

          if (discount === 0)
            return total + rateDaily * Number(rateEntry.daysInRate);

          // Calculate daily rate for each rate and add it to the total
          return (
            total +
            rateDaily *
              Number(rateEntry.daysInRate) *
              (1 - Number(discount) / 100)
          );
        }, 0)
      );
    };

    const totalDailyRate = matchingDates.reduce((total, rateEntry) => {
      // Assuming rate.weekly is the weekly rate for each rate
      const rateWeekly = Number(
        rateEntry.rate.weekly ? rateEntry.rate.weekly : props.fees.baseWeekly
      );
      const rateDaily = rateWeekly / 7;

      // Calculate daily rate for each rate and add it to the total
      return total + rateDaily * Number(rateEntry.daysInRate);
    }, 0);

    const totalDailyRateIncludingNotInRates =
      totalDailyRate + daysNotInRates * defaultDailyRate;

    const totalNotInRates = daysNotInRates * defaultDailyRate;

    let totalDailyRateWithDiscounts = totalDailyRateIncludingNotInRates;
    if (matchingDates.length > 0 && days >= 7 && days <= 13) {
      totalDailyRateWithDiscounts = calculateDailyRateWithDiscounts(
        matchingDates,
        1,
        totalNotInRates
      );
    } else if (matchingDates.length > 0 && days >= 14 && days <= 20) {
      totalDailyRateWithDiscounts = calculateDailyRateWithDiscounts(
        matchingDates,
        2,
        totalNotInRates
      );
    } else if (matchingDates.length > 0 && days >= 21 && days <= 27) {
      totalDailyRateWithDiscounts = calculateDailyRateWithDiscounts(
        matchingDates,
        3,
        totalNotInRates
      );
    } else if (matchingDates.length > 0 && days >= 28) {
      totalDailyRateWithDiscounts = calculateDailyRateWithDiscounts(
        matchingDates,
        4,
        totalNotInRates
      );
    }

    const total = totalDailyRateWithDiscounts;
    const totalCleaning = Number(props.fees.cleaningFee);

    const totalLodging = Number(props.fees.lodgingTax);
    const totalBooking = Number(props.fees.bookingFee);

    const totalWithFees = total + totalCleaning;
    const totalWithFeesAndTax =
      totalWithFees * (1 + (totalLodging + totalBooking) / 100);

    setTotals({
      cleaningFee: totalCleaning,
      bookingFee:
        totalWithFeesAndTax - totalDailyRateWithDiscounts - totalCleaning,
      totalTaxAndFees: totalWithFeesAndTax - totalDailyRateWithDiscounts,
      total: totalDailyRateWithDiscounts,
      estimatedTotal: totalWithFeesAndTax,
    });

    return;
  };

  return (
    <div>
      <div className="centered-div" id="bookingDiv" style={{ marginTop: "2%" }}>
        <div>
          <div>
            <div className="date-selectors-container">
              <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                <DateRangePicker
                  placeholder={"Start Date - End Date"}
                  value={[selectedStartDate, selectedEndDate]}
                  format="MM/dd/yyyy"
                  character=" – "
                  onChange={handleDateChange}
                  shouldDisableDate={(date) => {
                    const tomorrow = new Date(date);
                    tomorrow.setDate(tomorrow.getDate() + 1);

                    // Convert the unavailable dates to Date objects
                    const unavailableDates = props.datesUnavailable.map(
                      (dateString) => new Date(dateString).toDateString()
                    );

                    // Check if the current date is outside the valid range
                    if (
                      date < new Date() ||
                      date >
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 2)
                        )
                    ) {
                      return true;
                    }

                    // Check if the current date is unavailable and ensure the next date is not unavailable
                    const isUnavailable = unavailableDates.includes(
                      date.toDateString()
                    );
                    const isNextDayAvailable = !unavailableDates.includes(
                      tomorrow.toDateString()
                    );

                    return isUnavailable && !isNextDayAvailable;
                  }}
                  showOneCalendar
                  editable={false}
                  showHeader={false}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <img
                src={subtractIconPath}
                alt="subtract"
                height="33px"
                style={{ cursor: "pointer", marginRight: "5px" }}
                onClick={handleSubtractGuest}
              />
              <p style={{ fontSize: "18px", margin: 0, padding: "5px" }}>
                {numGuests} {numGuests > 1 ? "Guests" : "Guest"}
              </p>
              <img
                src={guestsIconPath}
                alt="guests"
                height="33px"
                style={{ marginRight: "5px" }}
              />
              <img
                src={addIconPath}
                alt="add"
                height="33px"
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={handleAddGuest}
              />
            </div>

            {errorMessage && <div className="errorDiv">{errorMessage}</div>}

            <div id="goodDatesDiv">
              <div>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span>
                    <img
                      src={thumbsupIconPath}
                      alt="good"
                      height="33px"
                      style={{
                        verticalAlign: "middle",
                      }}
                    />
                    Your dates are available!
                  </span>
                </div>
                <br />
                <div className="totalsDiv" style={{ margin: "0 auto" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <b style={{ marginRight: "20px" }}>
                      Rate for {numDays} {numDays > 1 ? "nights" : "night"}
                    </b>
                    <b
                      style={{
                        marginLeft: "20px",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                      }}
                    >
                      $
                      {totals.total
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </span>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <b style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                      Cleaning Fee
                    </b>
                    <b>
                      $
                      {Number(totals.cleaningFee)
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </span>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <b style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                      Lodging Tax
                      <br />+ Booking Fee
                    </b>
                    <b>
                      $
                      {Number(totals.bookingFee)
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </span>

                  <hr></hr>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <b>Estimated Total</b>
                    <b>
                      $
                      {totals.estimatedTotal
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </span>
                </div>
              </div>
              <br />
              <br />
              <div style={{ margin: "5px", fontWeight: "bold" }}>Message:</div>
              <InputText
                id="message"
                rows="3"
                value={message}
                maxCharacters={250}
                multiline={true}
                onChange={handleMessageChange}
              />
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={submit}
                style={{
                  width: "20%",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="centered-div"
        id="successDiv"
        style={{ display: "none", fontWeight: "bold", fontSize: "1.3em" }}
      >
        <br />
        <br />
        <br />
        Your inquiry has been sent to the owner.
        <br />
        They will email you back with more information.
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default CreateInquiry;
