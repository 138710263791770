import React, { useState, useEffect } from "react";
import "./styles.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";

function Slideshow({ slideshowImages = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Preload images
  useEffect(() => {
    if (slideshowImages?.length > 0) {
      slideshowImages.forEach((image) => {
        if (image?.path) {
          const img = new Image();
          img.src = image.path;
        }
      });
    }
  }, [slideshowImages]);

  const handlePrevClick = (event) => {
    event.stopPropagation();
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + slideshowImages.length) % slideshowImages.length
    );
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slideshowImages.length);
  };

  const handleImageClick = (event) => {
    const x = event.nativeEvent.offsetX;
    const width = event.target.clientWidth;

    if (x < width / 2) {
      handlePrevClick(event);
    } else {
      handleNextClick(event);
    }
  };

  // Fallback for empty or undefined images array
  if (!slideshowImages || slideshowImages.length === 0) {
    return <div>No images available for the slideshow.</div>;
  }

  return (
    <div className="slideshow" onClick={handleImageClick}>
      <img
        src={slideshowImages[currentIndex]?.path || "default/path/to/image.jpg"}
        alt={`Slide ${currentIndex + 1}`}
        style={{ position: "relative" }}
      />
      <i className="arrow left" onClick={handlePrevClick}>
        &lt;
      </i>
      <i className="arrow right" onClick={handleNextClick}>
        &gt;
      </i>
    </div>
  );
}

export default Slideshow;
